import { HighlightedRequest } from '@components/highlightedRequest/highlightedRequest';
import { Location } from '@components/location/location';
import { useIsVenue } from '@hooks/use-is-venue';
import { useLiteStorefront } from '@hooks/use-lite-storefront';
import { isUIReadabilityEnhancementsSelector } from '@redux/experiments/selectors/ui-readability-enhancements';
import { useAppSelector } from '@redux/hooks';
import { Column, Row } from '@xo-union/tk-component-grid';
import { Img } from '@xo-union/tk-component-picture';
import { H3 } from '@xo-union/tk-ui-typography';
import classNames from 'classnames';
import { useAtomValue } from 'jotai';
import React, { type FC } from 'react';
import type { Decorated } from 'types/vendor';
import { travelAvailabilityAtom } from '../../../../jotai/storefront/organization';
import SecondaryCTA from '../../components/secondary_cta/SecondaryCta';
import { useStorefrontFeatureScannability } from '../../hooks/useStorefrontFeatureScannability';
import type { NavItemProps } from '../nav/types';
import ContactInfo from './components/contact_info';
import Social from './components/social';
import Styles from './styles.scss';

interface VendorInfoProps {
	vendor: Decorated;
}
const VendorInfo: FC<VendorInfoProps> = ({ vendor }) => {
	return (
		<div>
			<ContactInfo />
			<div>
				<Social social={vendor.social} vendor={vendor} isFooter />
			</div>
		</div>
	);
};

const Contact: FC = () => {
	const vendor = useAppSelector((state) => {
		return state.vendor.vendor;
	});
	const isUIReadabilityEnhancementsVariant = useAppSelector(
		isUIReadabilityEnhancementsSelector,
	);
	const logoUrl = vendor?.logo?.url;

	const isLiteStorefront = useLiteStorefront();
	const shouldShowScannability = useStorefrontFeatureScannability();
	const travelAvailability = useAtomValue(travelAvailabilityAtom);
	const isVenue = useIsVenue();

	const hasNoAddress =
		!vendor?.address?.address &&
		!vendor?.address?.city &&
		!vendor?.address?.state;
	const hasNoServiceArea = !vendor?.serviceArea;
	const isAddressPublic = vendor?.address.isPublic;

	const isLocationHiddenByPrivacy =
		!isAddressPublic && !travelAvailability && !isVenue;

	const isScannabilityOrLocationPrivDisabled =
		isLocationHiddenByPrivacy || !shouldShowScannability;

	const isContactWithoutLocation =
		(isScannabilityOrLocationPrivDisabled &&
			isUIReadabilityEnhancementsVariant) ||
		!isUIReadabilityEnhancementsVariant;

	if (!vendor || (isLiteStorefront && hasNoAddress && hasNoServiceArea)) {
		return null;
	}

	return (
		<div
			className={classNames(Styles.contactInfoSection, {
				[Styles.noDivider]: shouldShowScannability,
			})}
			id="contact"
		>
			<Row
				hGutters={false}
				className={isContactWithoutLocation ? Styles.inverseOrder : ''}
			>
				<Column sm="12" md="8" orderMd="1">
					{isContactWithoutLocation && (
						<H3 className={Styles.title}>Contact</H3>
					)}
					<VendorInfo vendor={vendor} />
				</Column>
				{logoUrl && (
					<Column
						md="4"
						orderMd="2"
						className={classNames(Styles.logo, {
							[Styles.marginTop]: isContactWithoutLocation,
						})}
					>
						<Img
							src={`${logoUrl}~rs_${isUIReadabilityEnhancementsVariant ? '350.216' : '216.104'}.fit?quality=90`}
							alt="Vendor Logo"
						/>
					</Column>
				)}
			</Row>
			{!isLiteStorefront && vendor.claimedStatus === 'CLAIMED' && (
				<Row className={Styles.fullWidth}>
					<Column
						sm="12"
						md="12"
						orderMd="1"
						className={Styles.messageVendorCta}
					>
						{isUIReadabilityEnhancementsVariant ? (
							<HighlightedRequest
								messageText="Want to book a visit?"
								buttonText="Start a conversation"
								initiator="Location CTA"
								hideIfLead
							/>
						) : shouldShowScannability ? (
							<HighlightedRequest
								messageText="Curious to learn more?"
								buttonText="Start a conversation"
								initiator="Contact CTA"
							/>
						) : (
							<SecondaryCTA
								sourceContent="Contact CTA"
								icon="send"
								headerText="Interested?"
								linkText="Ask about availability"
								placeholderRfqText="Ask about availability"
							/>
						)}
					</Column>
				</Row>
			)}
		</div>
	);
};

export const ContactLocation: FC<NavItemProps> = () => {
	const shouldShowScannability = useStorefrontFeatureScannability();
	const isMobile = useAppSelector((state) => state.viewport)?.isMobile;

	const isUIReadabilityEnhancementsVariant = useAppSelector(
		isUIReadabilityEnhancementsSelector,
	);

	return (
		<div className={Styles.contactLocationWrapper}>
			{shouldShowScannability && (
				<Location
					className={Styles.noDivider}
					forcedTitle={
						isUIReadabilityEnhancementsVariant
							? isMobile
								? 'Contact info & Service area'
								: 'Service area & Contact info'
							: null
					}
				/>
			)}
			<Contact />
		</div>
	);
};
