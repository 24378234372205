import { selectAssignment } from '@redux/experiments/selectors/index';
import type { UserEngagementInteractivityAssignment } from 'types/experiments';
import type { State } from 'types/redux';

export const userEngagementInteractivityAssignmentSelector = (state: State) => {
	return selectAssignment(state, [
		'userEngagementInteractivity',
	]) as UserEngagementInteractivityAssignment;
};

export const isUserEngagementInteractivitySelector = (state: State) => {
	const assignment = userEngagementInteractivityAssignmentSelector(state);
	return assignment === 'variant1';
};
